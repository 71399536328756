@mixin status-badge-base($bg-color, $txt-color) {
    font-weight: 400;
    background-color: $bg-color;
    color: $txt-color;
}

.mga-badge {
    &-status {
        &-odered {
            @include status-badge-base(#aaaaaa, #141414);
        }

        &-payment {
            @include status-badge-base(#ffaf64, #141414);
        }

        &-progress {
            @include status-badge-base(#34ccee, #141414);
        }

        &-delivery {
            @include status-badge-base(#8f8fff, #141414);
        }

        &-done {
            @include status-badge-base(#73ee6a, #141414);
        }

        &-canceled {
            @include status-badge-base(#cb3535, #fafafa);
        }
    }
}