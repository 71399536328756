$mga-color-box-size: 20px;

.mga-composition {
    min-height: calc(100vh - 2rem);
    padding-top: 1rem;
    padding-bottom: 10rem;

    @include media-breakpoint-up(md) {
        padding-top: 3rem;
    }

    &-carousel-container {
        // background-color: white;
        border-width: 0px;
        border-radius: 2%;

        height: 65vh;

        @include media-breakpoint-up(lg) {
            height: 75vh;
        }
    }

    &-product-img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        display: block;
        border-radius: 2%;
        filter: drop-shadow(1px 1px 3px #737373);
    }

    &-prod-number {
        font-size: 0.8rem;
        font-weight: bold;
        color: #5f5f5f;
        padding-top: 0px;
        padding-bottom: 0px;
        margin-top: 0px;
        margin-bottom: 0px;
    }

    &-sell-text {
        padding-top: 0.5rem;
        padding-bottom: 0px;
        margin-top: 0px;
        font-weight: bold;
    }

    &-description {
        .mga-btn-show {
            display: block;
            width: fit-content;
            height: fit-content;
            border-radius: 0.4rem;
            padding: 0px 0.5rem 0px;
            margin-top: 1px;

            background-color: #cccccc;
            border-width: 0px;

            font-size: 0.8rem;
        }
    }

    &-buy-row {
        position: static;
        padding-top: 0px;
        padding-bottom: 0px;

        @include media-breakpoint-down(sm) {
            position: fixed;
            bottom: 0;
            width: 100vw;
            padding: 0.5rem 1rem 0.5rem 1rem;
            margin-left: -1.3rem;
            z-index: 10;

            background-color: $mga-color-pink;
        }

        .mga-text-price {
            font-size: 1.5em;
            font-weight: bold;

            // @include media-breakpoint-down(lg) {
            //     font-size: 1em;
            // }
        }

        .mga-buy-btn {
            background-color: transparent;
            outline: none;
            border: none;

            &:hover {
                text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.6);
            }

            i {
                font-size: 1.5em;
                margin: 0px;
                padding: 0px;
                color: #000;

                // @include media-breakpoint-down(lg) {
                //     font-size: 1.2em;
                // }
            }
        }
    }

    &-logo {
        height: auto;
        width: 50%;

        @include media-breakpoint-up(md) {
            height: 100%;
            width: auto;
        }
    }

    &-stats {
        display: grid;
        max-width: 100vw;
        grid-template-columns: max-content 1fr;
        column-gap: 1.5rem;
        row-gap: 1rem;
        color: #5f5f5f;

        .mga-stats-title {
            font-weight: 500;
        }

        .mga-stats-value.mga-item-colors {
            display: grid;
            grid-template-columns: repeat(
                auto-fill,
                minmax($mga-color-box-size, 1fr)
            );
            column-gap: 0.3rem;
            row-gap: 0.2rem;
        }

        .mga-color-box {
            display: block;
            border-radius: 15%;
            border-width: 3px;
            width: $mga-color-box-size;
            height: $mga-color-box-size;
        }

        .mga-stats-value.mga-item-size {
            display: grid;
            grid-template-columns: max-content 1fr;
            column-gap: 0.3rem;
        }
    }

    .mga-text-price {
        font-size: 1.5rem;
        font-weight: bold;

        @include media-breakpoint-up(md) {
            font-size: 2rem;
        }
    }

    .mga-text-price-old {
        color: $mga-text-color-old-price;
        text-decoration-line: line-through;
        font-size: 1rem;
        padding-right: 0.5rem;

        @include media-breakpoint-up(md) {
            font-size: 1.2rem;
        }
    }
}
