.mga-landing {
    &-promo {
        &-navbtn {
            height: 2em;
            width: 2em;
            margin: 0px;
            margin-right: 1em;
            padding: auto;
            background-color: transparent;
            border: 0px;
            border-radius: 50%;
            text-align: center;

            &:hover {
                color: rgb(92, 92, 92);
            }
        }

        .mga-promo-grid {
            width: 100%;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: 40vh 40vh;
            column-gap: 0.5rem;
            row-gap: 0.5rem;
            padding-top: 20px;
            padding-bottom: 1rem;
            padding-left: 5px;
            padding-right: 0.5rem;

            @include media-breakpoint-up(lg) {
                grid-template-columns: repeat(4, 1fr);
                grid-template-rows: 35vh;
                column-gap: 1.5rem;
                row-gap: 1.5rem;
            }
        }
    }

    &-order {
        background-color: $mga-color-pink;

        @include media-breakpoint-up(md) {
            background-image: url('/media/design/archidea.png');
            background-repeat: no-repeat;
            background-size: auto 100%;
            background-position-x: 10%;
        }

        @include media-breakpoint-up(xl) {
            background-position-x: 20%;
        }

        &-search {
            width: 100%;
            margin: auto;

            @include media-breakpoint-up(md) {
                width: 30rem;
            }

            input {
                text-align: center;
            }
        }
    }

    &-container {
        height: 100%;
    }

    &-block {
        height: 95vh;
        min-height: 700px;
        margin-top: -1px;

        &.mga-block-min {
            @include media-breakpoint-down(md) {
                height: auto;
                min-height: 95vh;
            }
        }

        &-descr {
            margin-top: -1px;
        }

        .mga-landing-gallery {
            height: 90%;

            img {
                display: block;
                max-height: 90%;
                max-width: 90%;
                margin: auto;
            }

            &-carusel {
                height: 80%;

                @include media-breakpoint-up(lg) {
                    height: 100%;
                }
            }
        }

        .mga-description {
            color: $mga-color-white;
        }

        .mga-logo {
            height: 20vh;
            max-height: 300px;
            width: auto;
        }

        .mga-brand {
            position: relative;
            max-width: 90%;
            height: 100%;
        }

        .mga-logo-brand {
            width: 85%;
            height: auto;
        }

        .mga-contacts-row {
            @include media-breakpoint-up(lg) {
                height: 100%;
            }
        }

        .mga-contacts-header {
            color: $mga-color-pink;
        }

        .mga-contacts-text {
            color: $mga-color-white;

            @include media-breakpoint-up(lg) {
                font-size: 1.2rem;
            }

            a {
                display: block;
                text-decoration: none;
                color: $mga-color-white;

                &:hover {
                    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.7);
                    // color: $mga-color-light-grey;
                    transform: translate(0px, -0.125rem);
                }
            }
        }
    }

    &-gallery-btn {
        color: $mga-landing-txt-color;
        background: rgba(1, 1, 1, 0.25);
        min-width: 30%;

        &:hover {
            background: rgba(1, 1, 1, 0.5);
            color: $mga-color-pink;
        }

        @include media-breakpoint-down(lg) {
            min-width: 30vw;
            background: rgba(1, 1, 1, 0.4);
        }
    }

    &-exmpl {
        &-grid {
            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: auto;
            row-gap: 2rem;

            @include media-breakpoint-up(md) {
                grid-template-columns: repeat(2, 1fr);
                column-gap: 1rem;
                row-gap: 1rem;
            }

            @include media-breakpoint-up(lg) {
                grid-template-columns: repeat(4, 1fr);
                column-gap: 2rem;
                row-gap: 2rem;
            }
        }

        &-item {
            display: block;
            width: 100%;

            .card {
                height: 100%;
                -webkit-box-shadow: 0px 0px 3px rgba(50, 50, 50, 0.1);
                -moz-box-shadow: 0px 0px 3px rgba(50, 50, 50, 0.1);
                box-shadow: 0px 0px 3px rgba(50, 50, 50, 0.1);
            }

            .mga-product-like.checked {
                text-shadow: 0px 0px 2px rgba(80, 80, 80, 0.5);
            }
        }
    }
}
