.mga-contacts {
    .mga-contacts-row {
        @include media-breakpoint-up(lg) {
            height: 100%;
        }

        .card {
            -webkit-box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15);
            -moz-box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15);
            box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15);
        }
    }

    .mga-contacts-header {
        color: $mga-color-base-green;
    }

    .mga-contacts-text {
        color: $mga-color-base-green;

        @include media-breakpoint-up(lg) {
            font-size: 1.2rem;
        }

        a {
            display: block;
            text-decoration: none;
            color: $mga-color-base-green;

            &:hover {
                text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
                // color: $mga-color-light-grey;
                transform: translate(0px, -0.125rem);
            }
        }
    }

    iframe {
        -webkit-box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2);
        -moz-box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2);
        box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2);
    }
}
