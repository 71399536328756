.mga-gallery {
    &-grid {
        display: grid;
        // grid-template-areas: "sidebar main";
        grid-template-columns: 1fr 0fr;

        @include media-breakpoint-up(lg) {
            grid-template-columns: 3fr 1fr;
            gap: 2em;
        }
    }

    &-sidebar {
        @include media-breakpoint-up(lg) {
            position: -webkit-sticky;
            position: sticky;
            top: 4rem;
            display: block !important;
            height: calc(100vh - 5rem);
            padding-right: 0.25rem;
            margin-right: -0.25rem;
            overflow-y: auto;
            overflow-x: auto;
        }

        .form-control:focus {
            -webkit-box-shadow: none;
            -moz-box-shadow: none;
            box-shadow: none;
            border-color: $mga-color-pink-dark;
        }

        .form-check-input:checked {
            background-color: $mga-color-pink;
            border-color: $mga-color-pink;
        }

        .form-check-input:focus {
            -webkit-box-shadow: 0 0 0 0.25rem rgba(233, 191, 210, 0.25);
            -moz-box-shadow: 0 0 0 0.25rem rgba(233, 191, 210, 0.25);
            box-shadow: 0 0 0 0.25rem rgba(233, 191, 210, 0.25);
            border-color: $mga-color-pink-dark;
        }
    }

    &-more-btn {
        background-color: #fff;
        border: solid 1px #666666;

        &:hover {
            @include media-breakpoint-up(md) {
                background-color: $mga-color-pink;
                border: solid 1px $mga-color-pink-dark;
            }
        }
    }

    &-list {
        display: grid;
        grid-template-columns: 1fr;
        gap: 1em;

        padding-bottom: 2rem;
    }

    &-filter-row {
        padding-bottom: 1rem;

        a {
            text-decoration: none;
            color: inherit;
        }

        .mga-bnt-apply {
            background-color: $mga-color-light-green;
            color: #f6f6f6;

            &:active {
                background-color: $mga-color-base-green;
                color: #f6f6f6;
            }
        }

        .mga-bnt-clear {
            color: #ff4a4a;

            &:active {
                color: #9d2b2b;
            }
        }
    }

    &-filter-water {
        label,
        label:hover {
            color: black;
            --bs-btn-border-color: #3d4e51;
        }

        .btn-check + .btn:hover {
            color: $mga-color-pink-dark;
        }

        .btn-check:checked + .btn,
        :not(.btn-check) + .btn:active,
        .btn:first-child:active,
        .btn.active,
        .btn.show {
            background-color: $mga-color-pink;
            border-color: $mga-color-pink-dark;
            color: black;
        }
    }

    &-item-link {
        // text-decoration: none;

        @include media-breakpoint-up(md) {
            &:hover {
                color: $mga-color-base-green;
            }
        }

        h5 {
            font-size: 1.4rem;
        }
    }

    &-image-link {
        &:hover {
            @include media-breakpoint-up(lg) {
                img {
                    -webkit-box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.6);
                    -moz-box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.6);
                    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.6);
                }
            }
        }
    }
}

.mga-filter-toggle-btn {
    .btn-primary {
        background-color: $mga-color-pink;
        border-color: $mga-color-pink-dark;

        &:focus,
        &:hover,
        &:active {
            background-color: $mga-color-pink !important;
            border-color: $mga-color-pink-dark;
        }
    }
}
