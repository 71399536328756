$mga-color-delete-header-bg: #ff2d2d;
$mga-color-delete-header-text: #4e0000;

.mga-user {
    &-grid {
        display: grid;
        grid-template-columns: max-content 1fr;
        grid-template-rows: auto;
        grid-template-areas:
            'body body';
        column-gap: 0px;

        @include media-breakpoint-up(lg) {
            grid-template-areas:
                'nav body';
            column-gap: 2rem;
        }
    }

    &-nav {
        grid-area: nav;

        .card {
            width: 98%;
            height: auto;
            position: fixed;
            left: 0;
            bottom: 0;
            z-index: 10;
            padding: 0.5rem 0;
            margin-left: 1%;
            margin-right: 1%;

            @include media-breakpoint-up(lg) {
                position: relative;
                width: auto;
                height: calc(100vh - 100px);
                z-index: 0;
                margin: auto;
            }
        }

        &-row {
            display: block;
            padding-left: 1rem;
            padding-right: 1rem;
            padding-top: 0.1rem;
            padding-bottom: 0.1rem;

            @include media-breakpoint-up(lg) {
                width: 100%;
                padding-top: 0.5rem;
                padding-bottom: 0.5rem;
                padding-right: 2rem;
            }
        }

        a {
            text-decoration: none;
            color: inherit;

            &:hover {
                background-color: $mga-color-pink;
            }

            @include media-breakpoint-down(sm) {
                margin-left: 0.5rem;
                margin-right: 0.5rem;
            }
        }

        a.active {
            background-color: $mga-color-pink;
        }
    }

    &-body {
        grid-area: body;

        &-wrapper {
            height: auto;
            width: 100%;

            @include media-breakpoint-up(lg) {
                height: calc(100vh - 150px);
                overflow-y: scroll;
            }


        }
    }

    &-order {
        &-button {
            width: 100%;
            border: none;
            background-color: inherit;
            padding: 0;
        }

        &-grid {
            width: 100%;
            display: grid;
            grid-template-columns: max-content 3fr 1fr 1fr;
            grid-template-rows: auto;

            &.mga-user-order-header {
                grid-template-areas:
                    "icon title count total"
                    ". status count total";
            }

            &.mga-user-order-item {
                grid-template-areas:
                    "title title count total";
            }
        }

        &-btn-ico {
            grid-area: icon;
        }

        &-title {
            grid-area: title;

            a {
                text-decoration: none;
                color: inherit;

                &:hover {
                    font-weight: 500;
                }
            }
        }

        &-count {
            grid-area: count;
        }

        &-total {
            grid-area: total;
        }

        &-status {
            grid-area: status;
        }
    }

    &-favorite {
        &-grid {
            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: auto;
            row-gap: 1rem;

            @include media-breakpoint-up(md) {
                grid-template-columns: repeat(3, 1fr);
                column-gap: 1rem;
            }

            @include media-breakpoint-up(lg) {
                margin-right: 1rem;
                grid-template-columns: repeat(4, 1fr);
                column-gap: 1rem;
            }
        }
    }

    &-settings {
        &-delete {

            .accordion-button,
            .accordion-button:not(.collapsed) {
                color: $mga-color-delete-header-text;
                background-color: $mga-color-delete-header-bg;
                --bs-accordion-btn-bg: $mga-color-delete-header-bg;
            }

            .accordion-button:focus {
                border-color: $mga-color-delete-header-bg;
                box-shadow: none;
            }
        }
    }

    &-registration {
        &-form {
            width: 100%;
            justify-content: center;
            align-items: center;
            padding-top: 2rem;
            padding-bottom: 4rem;

            .card {
                width: 100%;

                @include media-breakpoint-up(md) {
                    width: 80%;
                }

                @include media-breakpoint-up(lg) {
                    width: 60%;
                }
            }
        }
    }

    &-login-wrapper {
        width: 100;
        height: calc(100vh - 100px);
        align-items: center;
        justify-content: center;
    }
}