.mga-order {
    &-trace-card {
        width: 100%;

        @include media-breakpoint-up(xl) {
            width: 75%;
        }
    }

    &-list {
        display: grid;
        grid-template-columns: 1fr 4fr 2fr 2fr min-content;
        grid-template-rows: auto;
        grid-template-areas:
            'image name count price btns';

        @include media-breakpoint-down(lg) {
            grid-template-areas:
                'image image image image image'
                'name name name name name'
                'count count price price btns';
            row-gap: 1rem;
        }

        &-img {
            grid-area: image;

            .mga-img-container {
                height: 15vh;
                width: 100%;
            }
        }

        &-name {
            grid-area: name;

            @include media-breakpoint-down(lg) {
                text-align: center;
                justify-content: center;
            }

            a {
                text-decoration: none;
                font-weight: 550;
                color: inherit;

                @include media-breakpoint-up(md) {
                    &:hover {
                        color: $mga-color-light-green;
                    }
                }
            }
        }

        &-price {
            grid-area: price;
            font-weight: 500;
        }

        &-count {
            grid-area: count;

            input {
                -moz-appearance: textfield;

                &::-webkit-outer-spin-button,
                &::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                }

                max-width: 4rem;
            }
        }

        &-btn {
            grid-area: btns;

            a {
                text-decoration: none;
                color: $mga-color-base-green;
                font-weight: 550;

                @include media-breakpoint-up(md) {
                    font-size: 1.5rem;

                    &:hover {
                        color: $mga-color-pink;
                    }
                }
            }
        }
    }

    .mga-trace-search {
        display: block;
        width: 100%;
        margin: auto;

        @include media-breakpoint-up(md) {
            width: 50%;
        }

        input {
            text-align: center;
            font-weight: 500;
        }

        button {
            border-color: $mga-color-base-green;

            &:hover,
            &:active {
                color: $mga-color-white;
                background-color: $mga-color-base-green;
            }
        }
    }
}