.mga-cart {
    &-totals {
        color: $mga-text-color-dark-grey;
        font-size: 1.2rem;
    }

    &-tbl {
        width: 100%;
        color: $mga-text-color-dark-grey;

        @include media-breakpoint-up(md) {
            width: 50%;
        }
        @include media-breakpoint-up(lg) {
            width: 30%;
        }
    }

    &-promo {
        &-toggle {
            color: $mga-text-color-dark-grey;
            &:hover {
                color: black;
            }

            .badge {
                font-size: 1rem;
                font-weight: 400;
                padding: 0.2em 0.6em 0.3em;
                background-color: white;
                color: $mga-text-color-dark-grey;
                -webkit-box-shadow: 0px 0px 0px 1px #aaa;
                -moz-box-shadow: 0px 0px 0px 1px #aaa;
                box-shadow: 0px 0px 0px 1px #aaa;
            }
        }
    }
}
