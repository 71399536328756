.test-bg-div {
    display: block;
    width: 100%;
    height: 300px;
    background: rgb(61, 78, 81);
    background: linear-gradient(90deg, rgb(61, 78, 81) 0%, rgb(106, 134, 139) 100%);
}

.gr1 {
    background: url("/media/design/texture1.png") repeat, linear-gradient(90deg, rgb(61, 78, 81) 0%, rgb(106, 134, 139) 100%);
}

.gr2 {
    background: url("/media/design/texture2.png") repeat, linear-gradient(90deg, rgb(61, 78, 81) 0%, rgb(106, 134, 139) 100%);
}

.gr3 {
    background: url("/media/design/texture3.png") repeat, linear-gradient(90deg, rgb(61, 78, 81) 0%, rgb(106, 134, 139) 100%);
}

.gr4 {
    background: url("/media/design/texture4.png") repeat, linear-gradient(90deg, rgb(61, 78, 81) 0%, rgb(106, 134, 139) 100%);
}