$like-color-uncheked: rgb(91, 91, 91);
$like-color-checked: #e9bfd2;
$like-shadow: 0px 0px 2px rgba(0, 0, 0, 0.8);

.mga-product {
    &-like-container {
        position: absolute;
        top: 0px;
        left: 0px;
    }

    &-like {
        padding: 0px;
        margin: 0px;
        background-color: transparent;
        border: none;

        font-size: 1.2em;
        color: $like-color-uncheked;

        &:hover {
            text-shadow: $like-shadow;
        }

        &.checked {
            color: $like-color-checked;
        }
    }

    &-image {
        height: 100%;
        width: auto;
        padding-top: 10px;

        &-container {
            height: 20vh;
            width: 100%;

            @include media-breakpoint-up(lg) {
                height: 20vh;
            }
        }
    }

    &-card {
        &-color-box {
            width: 20px;
            height: 20px;
            margin-right: 2px;
            border-radius: 20%;
        }

        &-stats {
            color: #686868;
        }

        &-sell-text {
            color: $mga-text-color-old-price;
            font-size: 0.8rem;
            font-weight: 500;

            @include media-breakpoint-up(lg) {
                font-size: 1rem;
                font-weight: 400;
            }
        }

        &-promo {
            margin: auto;
            width: 100%;
            height: 100%;
            border: 0px;

            &:hover {
                -webkit-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);
                -moz-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);
                box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);
            }

            .mga-product-badge {
                position: absolute;
                top: 0;
                right: 0;
                transform: translate(10%, -40%);

                color: black;
            }

            .mga-product-title {
                text-decoration: none;
                font-weight: bold;
                font-size: 0.9em;
                padding-left: 0.5em;
                padding-right: 0.5em;

                color: inherit;

                @include media-breakpoint-down(lg) {
                    font-size: 0.6em;
                }
            }

            .mga-text-price {
                font-size: 1.5rem;
                font-weight: bold;

                @include media-breakpoint-down(lg) {
                    font-size: 1rem;
                }
            }

            .mga-text-price-old {
                font-size: 1rem;
                color: $mga-text-color-old-price;
                text-decoration: line-through;

                @include media-breakpoint-down(lg) {
                    font-size: 0.8rem;
                }
            }

            .mga-buy-btn {
                background-color: transparent;
                outline: none;
                border: none;

                &:hover {
                    text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.6);
                }

                i {
                    font-size: 1.5em;
                    margin: 0px;
                    padding: 0px;
                    color: #000;

                    @include media-breakpoint-down(lg) {
                        font-size: 1.2em;
                    }
                }
            }
        }

        &-gallery {
            min-height: 75vh;

            @include media-breakpoint-up(md) {
                // min-height: 50vh;
                min-height: 40vh;
            }

            .mga-product-card-carousel {
                height: 50vh;

                @include media-breakpoint-down(md) {
                    height: 50vh;
                }
            }

            .mga-gallery-product-image {
                max-width: 100%;
                max-height: 100%;
            }

            .mga-product-price-row {
                padding-top: 0.5rem;

                @include media-breakpoint-up(md) {
                    padding-top: 1rem;
                }
            }

            .mga-text-price {
                font-size: 1.5rem;
                font-weight: bold;

                @include media-breakpoint-up(md) {
                    font-size: 2rem;
                }
            }

            .mga-text-price-old {
                color: $mga-text-color-old-price;
                text-decoration-line: line-through;
                font-size: 1rem;
                padding-right: 0.5rem;

                @include media-breakpoint-up(md) {
                    font-size: 1.2rem;
                }
            }

            .mga-buy-btn {
                background-color: transparent;
                outline: none;
                border: none;

                @include media-breakpoint-up(md) {
                    padding-right: 1rem;
                }

                &:hover {
                    text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.6);
                }

                i {
                    font-size: 2rem;
                    margin: 0px;
                    padding: 0px;
                    color: #000;

                    @include media-breakpoint-down(lg) {
                        font-size: 1.5rem;
                    }
                }
            }
        }

        &-favorite {
            .mga-favorite-img {
                display: block;
                width: 100%;
                height: 100px;
                background-color: red;
                border-top-left-radius: var(--bs-card-inner-border-radius);
                border-top-right-radius: var(--bs-card-inner-border-radius);
            }

            .mga-favorite-no-img {
                min-height: 150px;
                height: 100%;
                width: 100%;
                text-align: center;
                background-color: $mga-color-base-green;

                span {
                    color: $mga-color-white;
                    font-weight: 300;
                }
            }

            .mga-product-title {
                width: 100%;
                // font-size: 1.2rem;
                text-align: center;
                text-decoration: none;
                font-weight: 500;
                color: inherit;
            }

            .mga-text-price {
                font-size: 1.1rem;
                font-weight: 500;
                color: $mga-color-base-green;
            }

            .mga-text-price-old {
                font-size: 0.9rem;
                font-weight: 300;
                color: $mga-text-color-old-price;
                text-decoration: line-through;
            }

            .mga-buy-btn {
                background-color: transparent;
                outline: none;
                border: none;

                &:hover {
                    text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.6);
                }

                i {
                    font-size: 1.5em;
                    margin: 0px;
                    padding: 0px;
                    color: $mga-color-base-green;
                }
            }

            .mga-favorite-no-buy {
                line-height: 2.2rem;
                text-align: right;
                padding: 0;

                span {
                    display: inline-block;
                    vertical-align: middle;
                    line-height: 1rem;
                    font-size: 0.9rem;
                    font-weight: 300;
                    color: $mga-color-base-green;
                }
            }

            .mga-remove-favorite-container {
                position: absolute;
                top: 0;
                right: 0;

                .mga-favorite-remove-btn {
                    padding-top: 0.5rem;
                    padding-right: 0.5rem;
                    margin: 0px;
                    background-color: transparent;
                    border: none;

                    @include media-breakpoint-up(lg) {
                        padding-top: 0;
                        padding-right: 0.4rem;
                    }

                    i {
                        color: rgba(0, 0, 0, 0.8);
                    }
                }
            }
        }
    }
}
