.mga-checkout {
    &-grid {
        display: grid;
        grid-template-columns: 3fr 2fr;
        grid-template-areas:
            'client client'
            'order order';
        grid-template-rows: auto;
        column-gap: 2rem;
        row-gap: 2rem;

        @include media-breakpoint-up(xl) {
            grid-template-areas: 'client order';
        }
    }

    &-details {
        grid-area: order;

        .accordion-button {
            padding-left: 0px;
        }

        .accordion-button:not(.collapsed) {
            background-color: transparent;
            -webkit-box-shadow: none;
            -moz-box-shadow: none;
            box-shadow: none;
        }

        .accordion-button:focus {
            -webkit-box-shadow: none;
            -moz-box-shadow: none;
            box-shadow: none;
        }
    }

    &-client {
        grid-area: client;
    }

    &-textarea {
        height: 5rem;
    }

    &-bottom-line {
        padding-bottom: 2px;
        padding-left: 5px;
        padding-right: 5px;

        .card {
            background-color: $mga-color-pink;
        }

        .btn {
            background-color: white;
            color: $mga-color-base-green;
            font-size: 1.2rem;

            &:active,
            &:focus {
                background-color: $mga-color-base-green;
                color: $mga-color-white;
            }
        }

        .mga-checkout-price {
            font-size: 1.2rem;
            font-weight: 500;
        }
    }
}
