.mga-ordership {
    .card {
        width: 100%;
        -webkit-box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15);
        -moz-box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15);
        box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15);

        @include media-breakpoint-up(md) {
            width: 70%;
        }
    }

    &-grid {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 3fr;
        grid-template-areas: 'instruction instruction';
        grid-template-rows: auto;

        @include media-breakpoint-up(md) {
            grid-template-areas: 'number instruction';
        }
    }

    &-num {
        grid-area: number;
        display: block;
        height: 100px;
        width: 100%;
        align-items: end;
        justify-content: end;
        text-align: center;

        img {
            height: 100%;
            width: auto;
        }

        @include media-breakpoint-down(md) {
            display: none;
        }

        @include media-breakpoint-up(xl) {
            align-self: center;
        }
    }

    &-instruction {
        grid-area: instruction;
        text-align: start;

        &-num {
            width: 80px;
            height: auto;
            padding-right: 1rem;

            @include media-breakpoint-up(md) {
                display: none;
            }
        }
    }
}
