$mga-bg-gradient: url('/media/design/texture3.png') repeat,
    linear-gradient(90deg, rgb(61, 78, 81) 0%, rgb(106, 134, 139) 100%);
$mga-bg-transparent: url('/media/design/texture_white.png') repeat;
$mga-bg-pink: url('/media/design/texture_white.png') repeat, #e9bfd2;
$mga-bg-white: url('/media/design/texture_white.png') repeat, #fff;
$mga-landing-txt-color: #fdfdfd;
$mga-text-color-old-price: #737373;
$mga-text-color-dark-grey: #3d3d3d;
$mga-color-pink: #e9bfd2;
$mga-color-pink-dark: #b08e9d;
$mga-color-white: #efefef;
$mga-color-light-grey: #cecece;
$mga-color-base-green: #3d4e51;
$mga-color-light-green: #6a868b;
$mga-spinner-speed: 1.5s;

.mga-bg-gradient {
    background: $mga-bg-gradient;
}

.mga-bg-white {
    background: $mga-bg-white;
}

.mga-bg-transparent {
    background: $mga-bg-transparent;
}

.mga-text-white {
    color: $mga-landing-txt-color;
}

.mga-text-error {
    color: #db2b2b;
}

.mga-text-success {
    color: #41b53b;
}

#navbarLogo {
    padding-top: 0;
    padding-bottom: 0;

    img {
        // display: none;
        height: 34px;
        width: auto;
    }
}

.navbar {
    .offcanvas {
        background-color: transparent;

        &.show {
            background: $mga-bg-gradient;
            color: $mga-color-white;

            .nav-link {
                color: $mga-color-white;
            }

            .btn {
                color: $mga-color-white;
            }

            .nav-link.active {
                color: $mga-color-pink;
                font-weight: bold;
            }
        }
    }

    .nav-link.active {
        color: $mga-color-pink;
    }
}

.spinner-border {
    animation-duration: $mga-spinner-speed;
}

.mga-nav-cart {
    .btn {
        padding: 0.1rem 0.25rem 0.1rem 0rem;

        @include media-breakpoint-up(lg) {
            padding-left: 0.25rem;
        }

        .badge {
            background-color: $mga-color-pink;
            color: black;
        }
    }
}

.toast-container {
    position: fixed;
    top: 0;
    right: 0;
    padding-top: 60px;
    padding-right: 0.5rem;

    @include media-breakpoint-up(lg) {
        top: auto;
        bottom: 0;
        padding: 1rem;
    }
}

.mga-outline-btn {
    text-decoration: none;
    color: inherit;

    @include media-breakpoint-up(md) {
        &:hover {
            background-color: $mga-color-light-green;
            color: $mga-color-white;
        }
    }
}
