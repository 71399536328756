.mga-info {
    &-grid {
        display: grid;
        grid-template-columns: 1fr 1.5fr;
        grid-template-rows: auto;
        column-gap: 2rem;
        row-gap: 2rem;
        grid-template-areas:
            'image image'
            'title title'
            'body body'
            'buttons buttons';
        width: 80%;

        @include media-breakpoint-up(md) {
            grid-template-areas:
                'image title'
                'image body'
                'image buttons';
        }
    }

    &-image {
        grid-area: image;
    }

    &-title {
        grid-area: title;
        color: $mga-color-white;
    }

    &-body {
        grid-area: body;
        color: $mga-color-light-grey;
        font-size: 1.5rem;

        &-text {
            color: $mga-color-light-grey;
            font-size: 1.5rem;
        }
    }

    &-buttons {
        grid-area: buttons;
    }

    &-buttons .btn {
        color: $mga-color-white;
        background: rgba(1, 1, 1, 0.25);
    }

    &-buttons .btn:hover {
        background: rgba(1, 1, 1, 0.5);
        color: $mga-color-pink;
    }

    &-button {
        color: $mga-color-white;
        background: rgba(1, 1, 1, 0.25);
    }

    &-button:hover {
        background: rgba(1, 1, 1, 0.5);
        color: $mga-color-pink;
    }

    &-error-number {
        color: $mga-color-pink;
    }
}